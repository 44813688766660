let idElement = '#modalOktaForceMail';
let idElementForbidden = '#modalOktaLoginForbidden';

$(document).ready(function () {
    const navEntry = performance.getEntriesByType('navigation');
    if (navEntry[0] && navEntry[0].type !== 'reload') {
        $(idElement).modal();
        $(idElementForbidden).modal();
    }
});

$(document).on('click', ' .custom_close_modal', function (e) {
    $(idElement).modal('hide');
    $(idElementForbidden).modal('hide');
});
